<template>
  <div>
    <form @change="onFormChange()">
      <div v-if="props.title" class="form__pipedrive__title">
        <span>{{ props.title }}</span>
      </div>

      <div class="form__pipedrive__field">
        <label for="organism">Nom de votre organisme <span>*</span></label>
        <input id="organism" type="text" v-model="formData.organism" name="organism" required>
      </div>

      <!--      <div class="form__pipedrive__field">-->
      <!--        <label for="name">Votre Nom <span>*</span></label>-->
      <!--        <input id="name" type="text" v-model="formData.name" name="name" required>-->
      <!--      </div>-->

      <div class="form__pipedrive__field">
        <label for="email">Email <span>*</span></label>
        <input id="email" type="email" v-model="formData.email" name="email" required>
      </div>

      <div class="form__pipedrive__field">
        <label for="phone">Numéro de téléphone <span>*</span></label>
        <input id="phone" type="text" v-model="formData.phone" name="phone" required>
      </div>

      <!--      <div class="form__pipedrive__field">-->
      <!--        <label for="employeesNumber">Nombre de salariés</label>-->
      <!--        <select id="employeesNumber" v-model="formData.employeesNumber" name="employeesNumber">-->
      <!--          <option value="" disabled selected>Veuillez sélectionner</option>-->
      <!--          <option value="1-5">1-5</option>-->
      <!--          <option value="5-25">5-25</option>-->
      <!--          <option value="25-50">25-50</option>-->
      <!--          <option value="50-100">50-100</option>-->
      <!--          <option value="100-500">100-500</option>-->
      <!--          <option value="500-1000">500-1000</option>-->
      <!--          <option value="1000+">1000+</option>-->
      <!--        </select>-->
      <!--      </div>-->


      <p class="mt-3 form__pipedrive__legal">
        En soumettant ce formulaire, j’accepte que les informations saisies soient utilisées dans le cadre de ma
        demande de contact. Pour plus d'informations, veuillez consulter nos
        <a href="https://blockproof.fr/privacy">mentions légales.</a>
      </p>

      <p class="text-center form__pipedrive__legal font-semibold">
        Demande gratuite et sans engagement
      </p>
    </form>

    <div class="mt-3 form__pipedrive__button_container">
      <PrimeButton
          class="text-sm lg:text-lg"
          :disabled="isFormInvalid || isSending"
          @click="submitForm()">
        <!--          :style="{'cursor': cursorStyle}" -->
        Valider
      </PrimeButton>
    </div>
  </div>
</template>


<script setup lang="ts">
import {useNuxtApp, useRoute, useRouter, useRuntimeConfig} from '#imports';
import {merge} from 'lodash-es';
import {sha256} from 'ohash';


const {$gtag, $emitter} = useNuxtApp();
const runtimeConfig = useRuntimeConfig();
const router = useRouter();
const route = useRoute();

const props = defineProps(['title'])

let isFormInvalid = ref(true);
let isSending = ref(false);

let formData = {
  email: null,      // Pipedrive Mandatory
  name: null,       // Pipedrive Mandatory
  organism: null,   // Pipedrive Mandatory
  phone: null,
  employeesNumber: null,
  utm_source: route.query.utm_source,
  // utm_medium: route.query.utm_medium,
  utm_campaign: route.query.utm_campaign,
  gclid: route.query.gclid,
};


// let cursorStyle = computed(() => {
//   console.log('cursorStyle', isSending.value, isFormInvalid.value)
//   if (isSending.value) return 'wait';
//   else if (isFormInvalid.value) return 'not-allowed';
//   else return 'pointer';
// })


const onFormChange = () => isFormInvalid.value = !formData.organism || !formData.email || !formData.phone;


const submitForm = async () => {
  console.log('Envoie du formulaire', formData);

  isSending.value = true;

  try {
    const response = await $fetch(runtimeConfig.public.apiBase + '/api/pipedrive/form', {
      method: 'POST',
      body: formData
    });

    console.log('Formulaire reçu');

    // close modal
    $emitter.emit('contactModalEvent', {show: false});

    // Custom event for analytics
    // used as conversion event : https://analytics.google.com/analytics/web/#/a140721970p327945200/admin/attribution/conversions
    $gtag('event', 'form', {
      'event_category': 'form',
      // 'event_label': 'Form',
    });

    // Custom event for ads
    const transaction_id = sha256(formData.email || 'anonymous').toString().substring(0, 32); // 64 char max
    // see : https://developers.google.com/tag-platform/gtagjs/routing?hl=fr
    $gtag('event', 'conversion', {
      // 'send_to': runtimeConfig.public.AW_MEASUREMENT_ID + '/' + runtimeConfig.public.AW_CONVERSION_LABEL,
      'transaction_id': transaction_id,
    });

    // page succeed
    const query = merge(route.query, {channel: 'webform'});
    await router.push({path: '/succeed', query});
  } catch (error) {
    console.error(error); // TODO
  } finally {
    isSending.value = false;
  }
}
</script>


<style lang="scss" scoped>

.form__pipedrive__title {
  text-align: center;

  font-size: 1.1rem;
  font-weight: 500;
}

.form__pipedrive__legal {
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: justify;
}

.form__pipedrive__field {
  margin-top: 16px;

  input, select {
    width: 100%;
    height: 38px;

    line-height: 38px;
    border-color: #325bc7;
    border-radius: 4px;
  }

  label {
    font-size: 0.9rem;

    span {
      font-weight: bold;
      color: red;
    }
  }
}

.form__pipedrive__button_container {
  display: flex;
  justify-content: center;
}
</style>
